<template>
  <div>
    <v-card v-if="tokenCheck == 1" style="width: 100%" min-width="400" max-width="500" class="pa-2">
      <div v-if="view == 1">
        <v-card-title class="title font-weight-regular justify-center">
          <span>Регистрация</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <a-form-model v-model="data" :model="model" :errors="errors" @validate="validate($event)" />
            <v-checkbox v-if="needAgree" v-model="isAgree" class="pt-0 mt-0">
              <template v-slot:label>
                <div class="pl-1">
                  Согласен с
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <a v-if="isOferta" href="#" @click.prevent.stop="showOffer = true" v-on="on">
                        Политикой конфиденциальности и Условиями электронного взаимодействия
                      </a>
                      <a v-else target="_blank" :href="`${$root.config.siteUrl}/oferta_client.html`" @click.stop v-on="on">
                        Политикой конфиденциальности и условиями электронного взаимодействия
                      </a>
                    </template>
                    Открыть
                  </v-tooltip>
                </div>
              </template>
            </v-checkbox>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn :disabled="!isAgree" color="primary" @click.prevent.stop="submit()">Далее</v-btn>
        </v-card-actions>
      </div>
      <div v-if="view == 2">
        <v-card-title class="text-h5"> Введите код из СМС </v-card-title>
        <v-card-text>
          Пожалуйста, введите одноразовый код из SMS, отправленный на ваш номер телефона, чтобы подтвердить ваше согласие с условиями.
          <div>
            <v-btn :disabled="sending" color="red darken-1" text @click="onGetCode()"> {{ interval.send > 0 ? interval.send + " " : "" }} Выслать код </v-btn>
          </div>
          <v-otp-input v-model="code" length="4" type="number"></v-otp-input>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="view = 1"> Назад </v-btn>
          <v-spacer> </v-spacer>
          <v-btn :disabled="code?.length !== 4 || confirming" color="green darken-1" text @click="onSendCode()">
            {{ interval.confirm > 0 ? interval.confirm + " " : "" }} Отправить
          </v-btn>
        </v-card-actions>
      </div>
      <div v-if="view == 3">
        <v-card-title class="title font-weight-regular justify-center">
          <span>Регистрация</span>
        </v-card-title>
        <v-card-text>
          <p>Вы успешно зарегистрировались</p>
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" to="/">Перейти на главную</v-btn>
        </v-card-actions>
      </div>
    </v-card>
    <v-card v-else style="width: 100%" max-width="500" class="pa-4">
      <v-card-title class="title font-weight-regular justify-center">
        <span>{{ tokenError }}</span>
      </v-card-title>
    </v-card>
    <portal to="v-app">
      <show-info />
      <Offer v-if="showOffer" v-model="showOffer" :content="oferta" @agree="isAgree = true" />
    </portal>
  </div>
</template>

<script>
import { validate, validators, fillForm } from "./../../components/mixings";
import Offer from "./offer.vue";

export default {
  components: { Offer },
  mixins: [validate, validators, fillForm],
  data() {
    return {
      isOferta: false,
      showOffer: false,
      view: 1,
      data: {},
      model: [
        { name: "name", title: "ФИО", type: "fio", validator: ["req"] },
        { name: "login", title: "Номер телефона", type: "number", validator: ["req", "tel2", "login"], mask: "7**********" },
        { name: "password", title: "Пароль", type: "password", validator: ["req", "pass"] },
        { name: "repassword", title: "Повторите пароль", type: "password", validator: ["req", "repass"] },
      ],
      token: this.$route.query?.token || "",
      invite: this.$route.query?.invite || "",
      errors: {},
      code: null,
      isAgree: false,
      needAgree: true,
      dialog: false,
      finish: false,
      sending: false,
      confirming: false,
      reg_data: null,
      interval: { send: 0, confirm: 0 },
      timer: { send: null, confirm: null },
      tokenCheck: 0,
      oferta: "",
      api: "/signup/registration",
    };
  },
  computed: {
    tokenError() {
      let res =
        this.tokenCheck == 2
          ? "Не верная ссылка на регистрацию"
          : this.tokenCheck == 3
          ? "Не удается проверить ссылку для регистрации"
          : this.tokenCheck == 4
          ? "Ошибка при проверки ссылки"
          : "Проверка ссылки на регистрацию";
      return res;
    },
  },
  created() {
    this.checkToken();
  },
  methods: {
    async checkToken() {
      this.tokenCheck = 0;
      if (this.token) {
        try {
          let response = await this.$axios.get("/accounting/doc/estimate/token/" + this.token);
          //let response = await this.$axios.post("/report_sql", { name: "checkRegistrationToken", params: { id: this.token } });
          if (response.status === 200 && response?.data?.data) {
            this.data["contract_token"] = this.$route.query?.token;
            this.reg_data = response.data.data;
            if (this.reg_data.id) {
              this.tokenCheck = 1;
            } else this.tokenCheck = 2;
          } else this.tokenCheck = 3;
        } catch (error) {
          this.tokenCheck = 4;
          this.$root.$emit("show-info", {
            type: "error",
            text: "Ошибка",
          });
          this.loading = false;
          return;
        }
      } else if (this.invite) {
        try {
          this.isOferta = true;
          this.showOffer = true;
          let response = await this.$axios.get("/mechti/invite_registration/token/" + this.invite);
          if (response.status === 200 && response?.data?.data) {
            this.data["invite_token"] = this.$route.query.invite;
            this.oferta = response.data?.oferta;
            this.reg_data = response.data.data;
            if (this.reg_data.id) {
              this.tokenCheck = 1;
              this.data.login = this.reg_data.whatsapp;
              let login = this.model.find((m) => m.name == "login");
              login["readonly"] = true;
              login.validator = ["req", "login"];
            } else this.tokenCheck = 2;
          } else this.tokenCheck = 3;
        } catch (error) {
          this.tokenCheck = 4;
          this.$root.$emit("show-info", {
            type: "error",
            text: "Ошибка",
          });
          this.loading = false;
          return;
        }
      } else if (this.$route.query?.partner) {
        try {
          this.isOferta = true;
          this.needAgree = true;
          this.showOffer = true;
          this.api = "/signup/registration/broker";
          this.data["partner_parent_ref"] = this.$route.query.partner;
          let response = await this.$axios.get("/signup/registration/broker");
          if (response.status === 200 && response?.data?.data) {
            this.oferta = response.data.data?.oferta;
          } else this.tokenCheck = 3;
          this.isAgree = !true;
          this.tokenCheck = 1;
          this.model.find((m) => m.name == "name").type = "string";
          this.model.find((m) => m.name == "login").validator = ["req", "login"];
        } catch (error) {
          this.tokenCheck = 4;
          this.$root.$emit("show-info", {
            type: "error",
            text: "Ошибка",
          });
          this.loading = false;
          return;
        }
      }
    },
    async submit() {
      let silent = true;
      /*if (this.$route.query?.token) {
        this.data["contract_token"] = this.$route.query?.token;
      } else if (this.$route.query?.invite) {
        this.data["invite_token"] = this.$route.query.invite;
      } else if (this.$route.query?.partner) {
        this.data["partner_parent_ref"] = this.$route.query.partner;
      } else {
        return;
      }*/
      if (await this.validateAll(this.data)) {
        try {
          let response = await this.$axios.post(this.api, this.data);
          if (response.data.data) {
            this.data["id"] = response.data.data.id;
            if (!silent)
              this.$root.$emit("show-info", {
                text: "Данные записаны",
              });
          }

          this.afterSave();
          this.view = 2;
        } catch (error) {
          this.$root.$emit("show-info", {
            type: "error",
            text: "Ошибка проверки правильности ввода " + (error?.data?.message || "null"),
          });
          this.loading = false;
          return;
        }
      }
    },
    afterSave(data, status) {},
    delay(name, n) {
      this.interval[name] = n;
      this.timer[name] = setInterval(() => {
        if (this.interval?.[name]) {
          this.interval[name]--;
        } else clearTimeout(this.timer[name]);
      }, 1000);
    },
    async onGetCode() {
      let id = this.data?.id;
      if (!id) return;
      this.sending = true;
      try {
        const d = await this.$axios.get("/signup/getconfirm/" + id);
        console.log(d.data);
        if (d.data && d.data.data?.result) {
          this.$root.$emit("show-info", {
            time: 10000,
            text: `СМС с кодом отправлена на номер  ${d.data.data.data.phone}`,
          });
        } else
          this.$root.$emit("show-info", {
            type: "error",
            text: "Ошибка при отправки СМС",
          });
      } catch (error) {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Ошибка " + error?.data?.message,
        });
      }
      this.delay("send", 60);
      setTimeout(() => {
        this.sending = false;
      }, 60000);
    },
    async onSendCode() {
      let id = this.data?.id;
      if (!id) return;
      this.confirming = true;
      try {
        const d = await this.$axios.post("/signup/sendconfirm/" + id, { code: this.code });
        console.log(d.data);
        if (d.data && d.data.data?.result) {
          this.$root.$emit("show-info", {
            text: "Код принят",
          });
          this.view = 3;
        } else
          this.$root.$emit("show-info", {
            type: "error",
            text: "Неверный код",
          });
      } catch (error) {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Запросите проверочный код.",
        });
        console.log(error?.message);
      }
      this.code = null;
      this.delay("confirm", 5);

      setTimeout(() => {
        this.confirming = false;
      }, 12000);
    },
  },
};
</script>
